export const getResourceUrl = (resource) => {
    const link = resource.links[0];

    switch (link.type) {
        case 'netflix':
            return `https://www.netflix.com/watch/${link.url}`;
        default:
            return link.url;
    }
};
