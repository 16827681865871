"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseResourceItem = void 0;
var parseResourceItem = exports.parseResourceItem = function parseResourceItem() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = item.type.toLowerCase();
  return {
    id: item.id,
    title: item.title,
    description: item.description,
    image: item.imageUrl,
    type: type,
    links: item.links.map(function (link) {
      return {
        type: link.type.toLowerCase(),
        url: link.url
      };
    }),
    highlighted: Boolean(item.isHighlighted),
    highlightedType: type === 'documentary' || type === 'video' ? 'movie' : type === 'challenge' ? 'challenge' : 'other',
    _raw: item
  };
};