"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  Alert: true,
  BigTabs: true,
  BigTab: true,
  Badge: true,
  Button: true,
  Card: true,
  Checkbox: true,
  FormFieldCheckbox: true,
  CodeInput: true,
  FormFieldCodeInput: true,
  DataTable: true,
  Column: true,
  Filters: true,
  Order: true,
  Search: true,
  TopRight: true,
  RowActions: true,
  DatePicker: true,
  FormFieldDatePicker: true,
  DropdownMenu: true,
  ErrorBoundary: true,
  LanguagePicker: true,
  SidebarLanguagePicker: true,
  Modal: true,
  ModalTrigger: true,
  ModalContainer: true,
  MultiSelect: true,
  NumberInput: true,
  FormFieldNumberInput: true,
  Pagination: true,
  Switch: true,
  PasswordInput: true,
  FormFieldPasswordInput: true,
  Popover: true,
  PopoverContent: true,
  PopoverTrigger: true,
  Preloader: true,
  PreloaderStates: true,
  PreloaderOutlet: true,
  Resource: true,
  AnimalIcon: true,
  Image: true,
  Empty: true,
  SearchBox: true,
  FiltersBox: true,
  Select: true,
  FormFieldSelect: true,
  Combobox: true,
  FormFieldCombobox: true,
  Separator: true,
  SortableContainer: true,
  SortableItem: true,
  Spinner: true,
  StandardForm: true,
  StandardFormContext: true,
  GeneratedStandardForm: true,
  StandardCheckbox: true,
  StandardCodeInput: true,
  StandardCombobox: true,
  StandardDatePicker: true,
  StandardTextInput: true,
  StandardNumberInput: true,
  StandardPasswordInput: true,
  StandardSelect: true,
  StandardSwitch: true,
  StandardTextarea: true,
  Translation: true,
  Textarea: true,
  FormFieldTextarea: true,
  TextInput: true,
  FormFieldTextInput: true,
  ThemeToggle: true,
  SidebarThemeToggle: true,
  Tooltip: true,
  UploadInput: true,
  FormFieldUploadInput: true,
  Avatar: true,
  AvatarImage: true,
  AvatarFallback: true,
  CardHeader: true,
  CardTitle: true,
  CardDescription: true,
  Carousel: true,
  CarouselContent: true,
  CarouselItem: true,
  CarouselNext: true,
  CarouselPrevious: true,
  Label: true,
  useFormField: true,
  Form: true,
  FormItem: true,
  FormLabel: true,
  FormControl: true,
  FormDescription: true,
  FormMessage: true,
  FormField: true,
  ResizablePanel: true,
  ResizablePanelGroup: true,
  ResizableHandle: true,
  Breadcrumb: true,
  BreadcrumbList: true,
  BreadcrumbItem: true,
  BreadcrumbLink: true,
  BreadcrumbPage: true,
  BreadcrumbSeparator: true,
  BreadcrumbEllipsis: true,
  buttonVariants: true,
  ScrollArea: true,
  ScrollBar: true,
  Sheet: true,
  SheetPortal: true,
  SheetOverlay: true,
  SheetTrigger: true,
  SheetClose: true,
  SheetContent: true,
  SheetHeader: true,
  SheetFooter: true,
  SheetTitle: true,
  SheetDescription: true,
  Sidebar: true,
  SidebarHeader: true,
  SidebarContent: true,
  SidebarGroup: true,
  SidebarGroupContent: true,
  SidebarGroupLabel: true,
  SidebarMenu: true,
  SidebarMenuButton: true,
  SidebarMenuItem: true,
  SidebarFooter: true,
  SidebarProvider: true,
  SidebarTrigger: true,
  SidebarRail: true,
  useSidebar: true,
  Collapsible: true,
  CollapsibleTrigger: true,
  CollapsibleContent: true,
  ShadSeparator: true,
  Skeleton: true,
  Tabs: true,
  TabsList: true,
  TabsTrigger: true,
  TabsContent: true,
  Table: true,
  TableHeader: true,
  TableBody: true,
  TableFooter: true,
  TableHead: true,
  TableRow: true,
  TableCell: true,
  TableCaption: true,
  VisuallyHidden: true,
  Toaster: true,
  cn: true,
  useToast: true,
  currentThemeState: true,
  translationsState: true,
  isDarkMode: true,
  camelizeObject: true,
  snakeObject: true,
  transformServerData: true,
  filtersEncode: true,
  filtersDecode: true,
  getServerFilters: true,
  parseLanguages: true,
  parseResources: true,
  parseResourceFeedbackOptions: true,
  traverseElements: true,
  objectToArray: true,
  arrayToObject: true,
  serverPermissionsToTablePermissions: true,
  getAppliedFiltersCount: true,
  getEmptyAuthToken: true,
  globals: true,
  useListingParams: true,
  useTranslations: true,
  useTheme: true,
  useFormSubmit: true,
  useRequest: true,
  useStandardSchema: true,
  useStandardForm: true,
  fetchRequest: true,
  setupApi: true,
  baseCSS: true
};
Object.defineProperty(exports, "Alert", {
  enumerable: true,
  get: function get() {
    return _Alert.Alert;
  }
});
Object.defineProperty(exports, "AnimalIcon", {
  enumerable: true,
  get: function get() {
    return _AnimalIcon.AnimalIcon;
  }
});
Object.defineProperty(exports, "Avatar", {
  enumerable: true,
  get: function get() {
    return _avatar.Avatar;
  }
});
Object.defineProperty(exports, "AvatarFallback", {
  enumerable: true,
  get: function get() {
    return _avatar.AvatarFallback;
  }
});
Object.defineProperty(exports, "AvatarImage", {
  enumerable: true,
  get: function get() {
    return _avatar.AvatarImage;
  }
});
Object.defineProperty(exports, "Badge", {
  enumerable: true,
  get: function get() {
    return _Badge.Badge;
  }
});
Object.defineProperty(exports, "BigTab", {
  enumerable: true,
  get: function get() {
    return _BigTabs.BigTab;
  }
});
Object.defineProperty(exports, "BigTabs", {
  enumerable: true,
  get: function get() {
    return _BigTabs.BigTabs;
  }
});
Object.defineProperty(exports, "Breadcrumb", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.Breadcrumb;
  }
});
Object.defineProperty(exports, "BreadcrumbEllipsis", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbEllipsis;
  }
});
Object.defineProperty(exports, "BreadcrumbItem", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbItem;
  }
});
Object.defineProperty(exports, "BreadcrumbLink", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbLink;
  }
});
Object.defineProperty(exports, "BreadcrumbList", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbList;
  }
});
Object.defineProperty(exports, "BreadcrumbPage", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbPage;
  }
});
Object.defineProperty(exports, "BreadcrumbSeparator", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.BreadcrumbSeparator;
  }
});
Object.defineProperty(exports, "Button", {
  enumerable: true,
  get: function get() {
    return _Button.Button;
  }
});
Object.defineProperty(exports, "Card", {
  enumerable: true,
  get: function get() {
    return _Card.Card;
  }
});
Object.defineProperty(exports, "CardDescription", {
  enumerable: true,
  get: function get() {
    return _card.CardDescription;
  }
});
Object.defineProperty(exports, "CardHeader", {
  enumerable: true,
  get: function get() {
    return _card.CardHeader;
  }
});
Object.defineProperty(exports, "CardTitle", {
  enumerable: true,
  get: function get() {
    return _card.CardTitle;
  }
});
Object.defineProperty(exports, "Carousel", {
  enumerable: true,
  get: function get() {
    return _carousel.Carousel;
  }
});
Object.defineProperty(exports, "CarouselContent", {
  enumerable: true,
  get: function get() {
    return _carousel.CarouselContent;
  }
});
Object.defineProperty(exports, "CarouselItem", {
  enumerable: true,
  get: function get() {
    return _carousel.CarouselItem;
  }
});
Object.defineProperty(exports, "CarouselNext", {
  enumerable: true,
  get: function get() {
    return _carousel.CarouselNext;
  }
});
Object.defineProperty(exports, "CarouselPrevious", {
  enumerable: true,
  get: function get() {
    return _carousel.CarouselPrevious;
  }
});
Object.defineProperty(exports, "Checkbox", {
  enumerable: true,
  get: function get() {
    return _Checkbox.Checkbox;
  }
});
Object.defineProperty(exports, "CodeInput", {
  enumerable: true,
  get: function get() {
    return _CodeInput.CodeInput;
  }
});
Object.defineProperty(exports, "Collapsible", {
  enumerable: true,
  get: function get() {
    return _collapsible.Collapsible;
  }
});
Object.defineProperty(exports, "CollapsibleContent", {
  enumerable: true,
  get: function get() {
    return _collapsible.CollapsibleContent;
  }
});
Object.defineProperty(exports, "CollapsibleTrigger", {
  enumerable: true,
  get: function get() {
    return _collapsible.CollapsibleTrigger;
  }
});
Object.defineProperty(exports, "Column", {
  enumerable: true,
  get: function get() {
    return _DataTable.Column;
  }
});
Object.defineProperty(exports, "Combobox", {
  enumerable: true,
  get: function get() {
    return _Combobox.Combobox;
  }
});
Object.defineProperty(exports, "DataTable", {
  enumerable: true,
  get: function get() {
    return _DataTable.DataTable;
  }
});
Object.defineProperty(exports, "DatePicker", {
  enumerable: true,
  get: function get() {
    return _DatePicker.DatePicker;
  }
});
Object.defineProperty(exports, "DropdownMenu", {
  enumerable: true,
  get: function get() {
    return _DropdownMenu.DropdownMenu;
  }
});
Object.defineProperty(exports, "Empty", {
  enumerable: true,
  get: function get() {
    return _Empty.Empty;
  }
});
Object.defineProperty(exports, "ErrorBoundary", {
  enumerable: true,
  get: function get() {
    return _ErrorBoundary.ErrorBoundary;
  }
});
Object.defineProperty(exports, "Filters", {
  enumerable: true,
  get: function get() {
    return _DataTable.Filters;
  }
});
Object.defineProperty(exports, "FiltersBox", {
  enumerable: true,
  get: function get() {
    return _FiltersBox.FiltersBox;
  }
});
Object.defineProperty(exports, "Form", {
  enumerable: true,
  get: function get() {
    return _form.Form;
  }
});
Object.defineProperty(exports, "FormControl", {
  enumerable: true,
  get: function get() {
    return _form.FormControl;
  }
});
Object.defineProperty(exports, "FormDescription", {
  enumerable: true,
  get: function get() {
    return _form.FormDescription;
  }
});
Object.defineProperty(exports, "FormField", {
  enumerable: true,
  get: function get() {
    return _form.FormField;
  }
});
Object.defineProperty(exports, "FormFieldCheckbox", {
  enumerable: true,
  get: function get() {
    return _Checkbox.FormFieldCheckbox;
  }
});
Object.defineProperty(exports, "FormFieldCodeInput", {
  enumerable: true,
  get: function get() {
    return _CodeInput.FormFieldCodeInput;
  }
});
Object.defineProperty(exports, "FormFieldCombobox", {
  enumerable: true,
  get: function get() {
    return _Combobox.FormFieldCombobox;
  }
});
Object.defineProperty(exports, "FormFieldDatePicker", {
  enumerable: true,
  get: function get() {
    return _DatePicker.FormFieldDatePicker;
  }
});
Object.defineProperty(exports, "FormFieldNumberInput", {
  enumerable: true,
  get: function get() {
    return _NumberInput.FormFieldNumberInput;
  }
});
Object.defineProperty(exports, "FormFieldPasswordInput", {
  enumerable: true,
  get: function get() {
    return _PasswordInput.FormFieldPasswordInput;
  }
});
Object.defineProperty(exports, "FormFieldSelect", {
  enumerable: true,
  get: function get() {
    return _Select.FormFieldSelect;
  }
});
Object.defineProperty(exports, "FormFieldTextInput", {
  enumerable: true,
  get: function get() {
    return _TextInput.FormFieldTextInput;
  }
});
Object.defineProperty(exports, "FormFieldTextarea", {
  enumerable: true,
  get: function get() {
    return _Textarea.FormFieldTextarea;
  }
});
Object.defineProperty(exports, "FormFieldUploadInput", {
  enumerable: true,
  get: function get() {
    return _UploadInput.FormFieldUploadInput;
  }
});
Object.defineProperty(exports, "FormItem", {
  enumerable: true,
  get: function get() {
    return _form.FormItem;
  }
});
Object.defineProperty(exports, "FormLabel", {
  enumerable: true,
  get: function get() {
    return _form.FormLabel;
  }
});
Object.defineProperty(exports, "FormMessage", {
  enumerable: true,
  get: function get() {
    return _form.FormMessage;
  }
});
Object.defineProperty(exports, "GeneratedStandardForm", {
  enumerable: true,
  get: function get() {
    return _StandardForm.GeneratedStandardForm;
  }
});
Object.defineProperty(exports, "Image", {
  enumerable: true,
  get: function get() {
    return _Image.Image;
  }
});
Object.defineProperty(exports, "Label", {
  enumerable: true,
  get: function get() {
    return _label.Label;
  }
});
Object.defineProperty(exports, "LanguagePicker", {
  enumerable: true,
  get: function get() {
    return _LanguagePicker.LanguagePicker;
  }
});
Object.defineProperty(exports, "Modal", {
  enumerable: true,
  get: function get() {
    return _Modal.Modal;
  }
});
Object.defineProperty(exports, "ModalContainer", {
  enumerable: true,
  get: function get() {
    return _Modal.ModalContainer;
  }
});
Object.defineProperty(exports, "ModalTrigger", {
  enumerable: true,
  get: function get() {
    return _Modal.ModalTrigger;
  }
});
Object.defineProperty(exports, "MultiSelect", {
  enumerable: true,
  get: function get() {
    return _MultiSelect.MultiSelect;
  }
});
Object.defineProperty(exports, "NumberInput", {
  enumerable: true,
  get: function get() {
    return _NumberInput.NumberInput;
  }
});
Object.defineProperty(exports, "Order", {
  enumerable: true,
  get: function get() {
    return _DataTable.Order;
  }
});
Object.defineProperty(exports, "Pagination", {
  enumerable: true,
  get: function get() {
    return _Pagination.Pagination;
  }
});
Object.defineProperty(exports, "PasswordInput", {
  enumerable: true,
  get: function get() {
    return _PasswordInput.PasswordInput;
  }
});
Object.defineProperty(exports, "Popover", {
  enumerable: true,
  get: function get() {
    return _Popover.Popover;
  }
});
Object.defineProperty(exports, "PopoverContent", {
  enumerable: true,
  get: function get() {
    return _Popover.PopoverContent;
  }
});
Object.defineProperty(exports, "PopoverTrigger", {
  enumerable: true,
  get: function get() {
    return _Popover.PopoverTrigger;
  }
});
Object.defineProperty(exports, "Preloader", {
  enumerable: true,
  get: function get() {
    return _Preloader.Preloader;
  }
});
Object.defineProperty(exports, "PreloaderOutlet", {
  enumerable: true,
  get: function get() {
    return _Preloader.PreloaderOutlet;
  }
});
Object.defineProperty(exports, "PreloaderStates", {
  enumerable: true,
  get: function get() {
    return _Preloader.PreloaderStates;
  }
});
Object.defineProperty(exports, "ResizableHandle", {
  enumerable: true,
  get: function get() {
    return _resizable.ResizableHandle;
  }
});
Object.defineProperty(exports, "ResizablePanel", {
  enumerable: true,
  get: function get() {
    return _resizable.ResizablePanel;
  }
});
Object.defineProperty(exports, "ResizablePanelGroup", {
  enumerable: true,
  get: function get() {
    return _resizable.ResizablePanelGroup;
  }
});
Object.defineProperty(exports, "Resource", {
  enumerable: true,
  get: function get() {
    return _Resource.Resource;
  }
});
Object.defineProperty(exports, "RowActions", {
  enumerable: true,
  get: function get() {
    return _DataTable.RowActions;
  }
});
Object.defineProperty(exports, "ScrollArea", {
  enumerable: true,
  get: function get() {
    return _scrollArea.ScrollArea;
  }
});
Object.defineProperty(exports, "ScrollBar", {
  enumerable: true,
  get: function get() {
    return _scrollArea.ScrollBar;
  }
});
Object.defineProperty(exports, "Search", {
  enumerable: true,
  get: function get() {
    return _DataTable.Search;
  }
});
Object.defineProperty(exports, "SearchBox", {
  enumerable: true,
  get: function get() {
    return _SearchBox.SearchBox;
  }
});
Object.defineProperty(exports, "Select", {
  enumerable: true,
  get: function get() {
    return _Select.Select;
  }
});
Object.defineProperty(exports, "Separator", {
  enumerable: true,
  get: function get() {
    return _Separator.Separator;
  }
});
Object.defineProperty(exports, "ShadSeparator", {
  enumerable: true,
  get: function get() {
    return _separator.Separator;
  }
});
Object.defineProperty(exports, "Sheet", {
  enumerable: true,
  get: function get() {
    return _sheet.Sheet;
  }
});
Object.defineProperty(exports, "SheetClose", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetClose;
  }
});
Object.defineProperty(exports, "SheetContent", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetContent;
  }
});
Object.defineProperty(exports, "SheetDescription", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetDescription;
  }
});
Object.defineProperty(exports, "SheetFooter", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetFooter;
  }
});
Object.defineProperty(exports, "SheetHeader", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetHeader;
  }
});
Object.defineProperty(exports, "SheetOverlay", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetOverlay;
  }
});
Object.defineProperty(exports, "SheetPortal", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetPortal;
  }
});
Object.defineProperty(exports, "SheetTitle", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetTitle;
  }
});
Object.defineProperty(exports, "SheetTrigger", {
  enumerable: true,
  get: function get() {
    return _sheet.SheetTrigger;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _sidebar.Sidebar;
  }
});
Object.defineProperty(exports, "SidebarContent", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarContent;
  }
});
Object.defineProperty(exports, "SidebarFooter", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarFooter;
  }
});
Object.defineProperty(exports, "SidebarGroup", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarGroup;
  }
});
Object.defineProperty(exports, "SidebarGroupContent", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarGroupContent;
  }
});
Object.defineProperty(exports, "SidebarGroupLabel", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarGroupLabel;
  }
});
Object.defineProperty(exports, "SidebarHeader", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarHeader;
  }
});
Object.defineProperty(exports, "SidebarLanguagePicker", {
  enumerable: true,
  get: function get() {
    return _LanguagePicker.SidebarLanguagePicker;
  }
});
Object.defineProperty(exports, "SidebarMenu", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarMenu;
  }
});
Object.defineProperty(exports, "SidebarMenuButton", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarMenuButton;
  }
});
Object.defineProperty(exports, "SidebarMenuItem", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarMenuItem;
  }
});
Object.defineProperty(exports, "SidebarProvider", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarProvider;
  }
});
Object.defineProperty(exports, "SidebarRail", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarRail;
  }
});
Object.defineProperty(exports, "SidebarThemeToggle", {
  enumerable: true,
  get: function get() {
    return _ThemeToggle.SidebarThemeToggle;
  }
});
Object.defineProperty(exports, "SidebarTrigger", {
  enumerable: true,
  get: function get() {
    return _sidebar.SidebarTrigger;
  }
});
Object.defineProperty(exports, "Skeleton", {
  enumerable: true,
  get: function get() {
    return _skeleton.Skeleton;
  }
});
Object.defineProperty(exports, "SortableContainer", {
  enumerable: true,
  get: function get() {
    return _Sortable.SortableContainer;
  }
});
Object.defineProperty(exports, "SortableItem", {
  enumerable: true,
  get: function get() {
    return _Sortable.SortableItem;
  }
});
Object.defineProperty(exports, "Spinner", {
  enumerable: true,
  get: function get() {
    return _Spinner.Spinner;
  }
});
Object.defineProperty(exports, "StandardCheckbox", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardCheckbox;
  }
});
Object.defineProperty(exports, "StandardCodeInput", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardCodeInput;
  }
});
Object.defineProperty(exports, "StandardCombobox", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardCombobox;
  }
});
Object.defineProperty(exports, "StandardDatePicker", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardDatePicker;
  }
});
Object.defineProperty(exports, "StandardForm", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardForm;
  }
});
Object.defineProperty(exports, "StandardFormContext", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardFormContext;
  }
});
Object.defineProperty(exports, "StandardNumberInput", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardNumberInput;
  }
});
Object.defineProperty(exports, "StandardPasswordInput", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardPasswordInput;
  }
});
Object.defineProperty(exports, "StandardSelect", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardSelect;
  }
});
Object.defineProperty(exports, "StandardSwitch", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardSwitch;
  }
});
Object.defineProperty(exports, "StandardTextInput", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardTextInput;
  }
});
Object.defineProperty(exports, "StandardTextarea", {
  enumerable: true,
  get: function get() {
    return _StandardForm.StandardTextarea;
  }
});
Object.defineProperty(exports, "Switch", {
  enumerable: true,
  get: function get() {
    return _Switch.Switch;
  }
});
Object.defineProperty(exports, "Table", {
  enumerable: true,
  get: function get() {
    return _table.Table;
  }
});
Object.defineProperty(exports, "TableBody", {
  enumerable: true,
  get: function get() {
    return _table.TableBody;
  }
});
Object.defineProperty(exports, "TableCaption", {
  enumerable: true,
  get: function get() {
    return _table.TableCaption;
  }
});
Object.defineProperty(exports, "TableCell", {
  enumerable: true,
  get: function get() {
    return _table.TableCell;
  }
});
Object.defineProperty(exports, "TableFooter", {
  enumerable: true,
  get: function get() {
    return _table.TableFooter;
  }
});
Object.defineProperty(exports, "TableHead", {
  enumerable: true,
  get: function get() {
    return _table.TableHead;
  }
});
Object.defineProperty(exports, "TableHeader", {
  enumerable: true,
  get: function get() {
    return _table.TableHeader;
  }
});
Object.defineProperty(exports, "TableRow", {
  enumerable: true,
  get: function get() {
    return _table.TableRow;
  }
});
Object.defineProperty(exports, "Tabs", {
  enumerable: true,
  get: function get() {
    return _tabs.Tabs;
  }
});
Object.defineProperty(exports, "TabsContent", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsContent;
  }
});
Object.defineProperty(exports, "TabsList", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsList;
  }
});
Object.defineProperty(exports, "TabsTrigger", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsTrigger;
  }
});
Object.defineProperty(exports, "TextInput", {
  enumerable: true,
  get: function get() {
    return _TextInput.TextInput;
  }
});
Object.defineProperty(exports, "Textarea", {
  enumerable: true,
  get: function get() {
    return _Textarea.Textarea;
  }
});
Object.defineProperty(exports, "ThemeToggle", {
  enumerable: true,
  get: function get() {
    return _ThemeToggle.ThemeToggle;
  }
});
Object.defineProperty(exports, "Toaster", {
  enumerable: true,
  get: function get() {
    return _toaster.Toaster;
  }
});
Object.defineProperty(exports, "Tooltip", {
  enumerable: true,
  get: function get() {
    return _Tooltip.Tooltip;
  }
});
Object.defineProperty(exports, "TopRight", {
  enumerable: true,
  get: function get() {
    return _DataTable.TopRight;
  }
});
Object.defineProperty(exports, "Translation", {
  enumerable: true,
  get: function get() {
    return _Translation.Translation;
  }
});
Object.defineProperty(exports, "UploadInput", {
  enumerable: true,
  get: function get() {
    return _UploadInput.UploadInput;
  }
});
Object.defineProperty(exports, "VisuallyHidden", {
  enumerable: true,
  get: function get() {
    return _visuallyHidden.VisuallyHidden;
  }
});
Object.defineProperty(exports, "arrayToObject", {
  enumerable: true,
  get: function get() {
    return _arrayToObject.arrayToObject;
  }
});
Object.defineProperty(exports, "baseCSS", {
  enumerable: true,
  get: function get() {
    return _base["default"];
  }
});
Object.defineProperty(exports, "buttonVariants", {
  enumerable: true,
  get: function get() {
    return _button.buttonVariants;
  }
});
Object.defineProperty(exports, "camelizeObject", {
  enumerable: true,
  get: function get() {
    return _camelizeObject.camelizeObject;
  }
});
Object.defineProperty(exports, "cn", {
  enumerable: true,
  get: function get() {
    return _utils.cn;
  }
});
Object.defineProperty(exports, "currentThemeState", {
  enumerable: true,
  get: function get() {
    return _recoilState.currentThemeState;
  }
});
Object.defineProperty(exports, "fetchRequest", {
  enumerable: true,
  get: function get() {
    return _fetchRequest.fetchRequest;
  }
});
Object.defineProperty(exports, "filtersDecode", {
  enumerable: true,
  get: function get() {
    return _filtersDecode.filtersDecode;
  }
});
Object.defineProperty(exports, "filtersEncode", {
  enumerable: true,
  get: function get() {
    return _filtersEncode.filtersEncode;
  }
});
Object.defineProperty(exports, "getAppliedFiltersCount", {
  enumerable: true,
  get: function get() {
    return _getAppliedFiltersCount.getAppliedFiltersCount;
  }
});
Object.defineProperty(exports, "getEmptyAuthToken", {
  enumerable: true,
  get: function get() {
    return _getEmptyAuthToken.getEmptyAuthToken;
  }
});
Object.defineProperty(exports, "getServerFilters", {
  enumerable: true,
  get: function get() {
    return _getServerFilters.getServerFilters;
  }
});
Object.defineProperty(exports, "globals", {
  enumerable: true,
  get: function get() {
    return _globals.globals;
  }
});
Object.defineProperty(exports, "isDarkMode", {
  enumerable: true,
  get: function get() {
    return _isDarkMode.isDarkMode;
  }
});
Object.defineProperty(exports, "objectToArray", {
  enumerable: true,
  get: function get() {
    return _objectToArray.objectToArray;
  }
});
Object.defineProperty(exports, "parseLanguages", {
  enumerable: true,
  get: function get() {
    return _parseLanguages.parseLanguages;
  }
});
Object.defineProperty(exports, "parseResourceFeedbackOptions", {
  enumerable: true,
  get: function get() {
    return _parseResourceFeedbackOptions.parseResourceFeedbackOptions;
  }
});
Object.defineProperty(exports, "parseResources", {
  enumerable: true,
  get: function get() {
    return _parseResources.parseResources;
  }
});
Object.defineProperty(exports, "serverPermissionsToTablePermissions", {
  enumerable: true,
  get: function get() {
    return _serverPermissionsToTablePermissions.serverPermissionsToTablePermissions;
  }
});
Object.defineProperty(exports, "setupApi", {
  enumerable: true,
  get: function get() {
    return _setupApi.setupApi;
  }
});
Object.defineProperty(exports, "snakeObject", {
  enumerable: true,
  get: function get() {
    return _snakeObject.snakeObject;
  }
});
Object.defineProperty(exports, "transformServerData", {
  enumerable: true,
  get: function get() {
    return _transformServerData.transformServerData;
  }
});
Object.defineProperty(exports, "translationsState", {
  enumerable: true,
  get: function get() {
    return _recoilState.translationsState;
  }
});
Object.defineProperty(exports, "traverseElements", {
  enumerable: true,
  get: function get() {
    return _traverseElements.traverseElements;
  }
});
Object.defineProperty(exports, "useFormField", {
  enumerable: true,
  get: function get() {
    return _form.useFormField;
  }
});
Object.defineProperty(exports, "useFormSubmit", {
  enumerable: true,
  get: function get() {
    return _useFormSubmit.useFormSubmit;
  }
});
Object.defineProperty(exports, "useListingParams", {
  enumerable: true,
  get: function get() {
    return _useListingParams.useListingParams;
  }
});
Object.defineProperty(exports, "useRequest", {
  enumerable: true,
  get: function get() {
    return _useRequest.useRequest;
  }
});
Object.defineProperty(exports, "useSidebar", {
  enumerable: true,
  get: function get() {
    return _sidebar.useSidebar;
  }
});
Object.defineProperty(exports, "useStandardForm", {
  enumerable: true,
  get: function get() {
    return _useStandardForm.useStandardForm;
  }
});
Object.defineProperty(exports, "useStandardSchema", {
  enumerable: true,
  get: function get() {
    return _useStandardSchema.useStandardSchema;
  }
});
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _useTheme.useTheme;
  }
});
Object.defineProperty(exports, "useToast", {
  enumerable: true,
  get: function get() {
    return _useToast.useToast;
  }
});
Object.defineProperty(exports, "useTranslations", {
  enumerable: true,
  get: function get() {
    return _useTranslations.useTranslations;
  }
});
var _Alert = require("./components/Alert");
var _BigTabs = require("./components/BigTabs");
var _Badge = require("./components/Badge");
var _Button = require("./components/Button");
var _Card = require("./components/Card");
var _Checkbox = require("./components/Checkbox");
var _CodeInput = require("./components/CodeInput");
var _DataTable = require("./components/DataTable");
var _DatePicker = require("./components/DatePicker");
var _DropdownMenu = require("./components/DropdownMenu");
var _ErrorBoundary = require("./components/ErrorBoundary");
var _LanguagePicker = require("./components/LanguagePicker");
var _Modal = require("./components/Modal");
var _MultiSelect = require("./components/MultiSelect");
var _NumberInput = require("./components/NumberInput");
var _Pagination = require("./components/Pagination");
var _Switch = require("./components/Switch");
var _PasswordInput = require("./components/PasswordInput");
var _Popover = require("./components/Popover");
var _Preloader = require("./components/Preloader");
var _Resource = require("./components/Resource");
var _AnimalIcon = require("./components/AnimalIcon");
var _Image = require("./components/Image");
var _Empty = require("./components/Empty");
var _SearchBox = require("./components/SearchBox");
var _FiltersBox = require("./components/FiltersBox");
var _Select = require("./components/Select");
var _Combobox = require("./components/Combobox");
var _Separator = require("./components/Separator");
var _Sortable = require("./components/Sortable");
var _Spinner = require("./components/Spinner");
var _StandardForm = require("./components/StandardForm");
var _Translation = require("./components/Translation");
var _Textarea = require("./components/Textarea");
var _TextInput = require("./components/TextInput");
var _ThemeToggle = require("./components/ThemeToggle");
var _Tooltip = require("./components/Tooltip");
var _UploadInput = require("./components/UploadInput");
var _avatar = require("./_shadcn/components/avatar");
var _card = require("./_shadcn/components/card");
var _carousel = require("./_shadcn/components/carousel");
var _label = require("./_shadcn/components/label");
var _form = require("./_shadcn/components/form");
var _resizable = require("./_shadcn/components/resizable");
var _breadcrumb = require("./_shadcn/components/breadcrumb");
var _button = require("./_shadcn/components/button");
var _scrollArea = require("./_shadcn/components/scroll-area");
var _sheet = require("./_shadcn/components/sheet");
var _sidebar = require("./_shadcn/components/sidebar");
var _collapsible = require("./_shadcn/components/collapsible");
var _separator = require("./_shadcn/components/separator");
var _skeleton = require("./_shadcn/components/skeleton");
var _tabs = require("./_shadcn/components/tabs");
var _table = require("./_shadcn/components/table");
var _visuallyHidden = require("./_shadcn/components/visually-hidden");
var _toaster = require("./_shadcn/components/toaster");
var _utils = require("./_shadcn/lib/utils");
var _useToast = require("./_shadcn/hooks/use-toast");
var _recoilState = require("./recoilState");
var _isDarkMode = require("./helpers/isDarkMode");
var _camelizeObject = require("./helpers/camelizeObject");
var _snakeObject = require("./helpers/snakeObject");
var _transformServerData = require("./helpers/transformServerData");
var _filtersEncode = require("./helpers/filtersEncode");
var _filtersDecode = require("./helpers/filtersDecode");
var _getServerFilters = require("./helpers/getServerFilters");
var _parseLanguages = require("./helpers/parseLanguages");
var _parseResources = require("./helpers/parseResources");
var _parseResourceFeedbackOptions = require("./helpers/parseResourceFeedbackOptions");
var _traverseElements = require("./helpers/traverseElements");
var _objectToArray = require("./helpers/objectToArray");
var _arrayToObject = require("./helpers/arrayToObject");
var _serverPermissionsToTablePermissions = require("./helpers/serverPermissionsToTablePermissions");
var _getAppliedFiltersCount = require("./helpers/getAppliedFiltersCount");
var _getEmptyAuthToken = require("./helpers/getEmptyAuthToken");
var _globals = require("./helpers/globals");
var _useListingParams = require("./hooks/useListingParams");
var _useTranslations = require("./hooks/useTranslations");
var _useTheme = require("./hooks/useTheme");
var _useFormSubmit = require("./hooks/useFormSubmit");
var _useRequest = require("./hooks/useRequest");
var _useStandardSchema = require("./hooks/useStandardSchema");
var _useStandardForm = require("./hooks/useStandardForm");
var _fetchRequest = require("./helpers/fetchRequest");
var _setupApi = require("./helpers/fetchRequest/api/setupApi");
var _base = _interopRequireDefault(require("./base.css"));
var _animalIcons = require("./resources/animalIcons");
Object.keys(_animalIcons).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _animalIcons[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _animalIcons[key];
    }
  });
});
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }