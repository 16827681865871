import { atom } from 'recoil';
import store from 'store2';

export const resourcesState = atom({
    key: 'resources',
    default: {},
});

export const highlightedState = atom({
    key: 'highlighted',
    default: {},
});

export const analyticsState = atom({
    key: 'analytics',
    default: {
        visitId: 0,
    },
});

// -----------------------------------------------------------------------------
// Current states (these change often)
// -----------------------------------------------------------------------------

export const userSelectedCodeState = atom({
    key: 'userSelectedCode',
    default: store.get('userSelectedCode') || false,
});

export const currentCodeState = atom({
    key: 'currentCode',
    default: null,
});

export const currentResourceState = atom({
    key: 'currentResource',
    default: '',
});
