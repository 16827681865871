import hexToHsl from 'hex-to-hsl';

export const StyleCustomizer = () => {
    const darkColor = hexToHsl('#ff0000');
    const lightColor = hexToHsl('#00ff00');

    return (
        <style type="text/css">
            {`
            :root {
                --wtf-pink: ${lightColor.join(' ')}
            }
            .dark {
                --wtf-pink: ${darkColor.join(' ')}
            }
        `}
        </style>
    )
}
