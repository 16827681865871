"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParamName = void 0;
var _lodash = require("lodash");
var getParamName = exports.getParamName = function getParamName() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return name.split('[').map(function (part1) {
    return part1.split(']').map(function (part2) {
      return (part2[0] === '_' ? '_' : '') + (0, _lodash.snakeCase)(part2);
    }).join(']');
  }).join('[');
};