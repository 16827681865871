const { REACT_APP_API_BASE } = process.env;

export const publicConfig = {
    /**
     * Base of the API
     */
    base: REACT_APP_API_BASE || '',

    /**
     * Endpoints for the public api
     */
    endpoints: {
        /**
         * Endpoint to validate code
         */
        validateCode: '/codes',
        /**
         * Endpoint to fetch all available languages
         */
        languages: '/locales',

        /**
         * Endpoint to fetch all translations
         */
        translations: '/contents',

        /**
         * Endpoint to fetch highlighted resources based on code
         */
        highlighted: '/resources/highlighted',

        /**
         * Endpoint to fetch home resources based on code
         */
        resources: '/resources',

        /**
         * Endpoint to fetch feedback options
         */
        feedback: '/feedback',

        /**
         * Endpoint to post the petitions
         */
        petitions: '/petitions',

        /**
         * Analytics
         */
        analyticsVisit: '/analytics/visits',
        analyticsResource: '/analytics/resource-accessed',
    },
};
