"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTranslations = void 0;
var _recoil = require("recoil");
var _lodash = require("lodash");
var _moment = _interopRequireDefault(require("moment"));
require("moment/locale/fr");
require("moment/locale/es");
require("moment/locale/it");
require("moment/locale/de");
require("moment/locale/tr");
require("moment/locale/pt");
require("moment/locale/nl");
require("moment/locale/id");
require("moment/locale/ru");
require("moment/locale/bg");
require("moment/locale/hi");
require("moment/locale/ka");
require("moment/locale/ar");
require("moment/locale/th");
require("moment/locale/he");
var _recoilState = require("../recoilState");
var _getInterpolatedString = require("../helpers/getInterpolatedString");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; } // Import moment locales
// import 'moment/locale/no';
// import 'moment/locale/zh';
var useTranslations = exports.useTranslations = function useTranslations() {
  // Handle translations
  var _useRecoilState = (0, _recoil.useRecoilState)(_recoilState.translationsState),
    _useRecoilState2 = _slicedToArray(_useRecoilState, 2),
    translations = _useRecoilState2[0],
    setTranslations = _useRecoilState2[1];
  var t = function t(key) {
    var translation = (0, _lodash.get)(translations, key, key);
    if (!(0, _lodash.isString)(translation)) {
      translation = key;
    }
    for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      params[_key - 1] = arguments[_key];
    }
    return _getInterpolatedString.getInterpolatedString.apply(void 0, [translation].concat(params));
  };

  // Handle languages
  var _useRecoilState3 = (0, _recoil.useRecoilState)(_recoilState.languagesState),
    _useRecoilState4 = _slicedToArray(_useRecoilState3, 2),
    languages = _useRecoilState4[0],
    setLanguages = _useRecoilState4[1];
  var _useRecoilState5 = (0, _recoil.useRecoilState)(_recoilState.currentLanguageState),
    _useRecoilState6 = _slicedToArray(_useRecoilState5, 2),
    currentLanguage = _useRecoilState6[0],
    _setCurrentLanguage = _useRecoilState6[1];
  return {
    // Pass the translations helpers
    t: t,
    setTranslations: setTranslations,
    // Pass the languages helpers
    languages: languages,
    setLanguages: setLanguages,
    currentLanguage: currentLanguage,
    setCurrentLanguage: function setCurrentLanguage(item) {
      // Set the language for moment
      _moment["default"].locale(item.code);

      // Save to recoil
      _setCurrentLanguage(item);
    }
  };
};