import { PlayIcon, AppleIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import {
    Button,
    Image,
    Modal,
    ModalContainer,
    useTranslations,
} from 'wtf-components';

import './style.scss';
import { isFunction } from 'lodash';

export const ResourceViewer = ({ item, open, onOpenChange }) => {
    const { t } = useTranslations();
    const link = item.links[0];
    const plyrProps = {
        source: {
            type: 'video',
            sources: [
                {
                    src: link.url,
                    provider: link.type,
                },
            ],
        },
        options: {
            autoplay: true,
        },
    };

    return (
        <div className="resource-viewer">
            <ModalContainer open={open} onOpenChange={onOpenChange}>
                <Modal
                    aspectRatio="video"
                    className="w-full sm:w-2/3 overflow-hidden"
                    setWidth={false}
                    title={item.title}
                    description={item.description}
                >
                    {link.type === 'embedded' && (
                        <div
                            className="embedded"
                            dangerouslySetInnerHTML={{ __html: link.url }}
                        />
                    )}
                    {(link.type === 'youtube' || link.type === 'vimeo') && (
                        <Plyr {...plyrProps} />
                    )}
                    {(link.type === 'playstore' ||
                        link.type === 'appstore') && (
                        <div className="flex flex-row gap-4">
                            <div className="w-32">
                                <Image src={item.image} />
                            </div>
                            <div className="flex-grow space-y-3">
                                {item.links.map((link) => (
                                    <Button
                                        key={`link-${link.type}`}
                                        variant="outline"
                                        className="w-full"
                                        asChild
                                        onClick={() => {
                                            if (isFunction(onOpenChange)) {
                                                onOpenChange(false);
                                            }
                                        }}
                                    >
                                        <Link
                                            to={link.url}
                                            target="_blank"
                                            rel="nofollow"
                                        >
                                            {link.type === 'playstore' && (
                                                <PlayIcon className="me-2 h-6 w-6" />
                                            )}
                                            {link.type === 'appstore' && (
                                                <AppleIcon className="me-2 h-6 w-6" />
                                            )}
                                            {t('app-' + link.type)}
                                        </Link>
                                    </Button>
                                ))}
                            </div>
                        </div>
                    )}
                </Modal>
            </ModalContainer>
        </div>
    );
};
