"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInterpolatedArray = void 0;
/**
 * Interpolates variables wrapped with `{}` in `str` with variables in `obj`
 * It will replace what it can, and leave the rest untouched
 *
 * @param {String} str
 * @param {Array|Object} obj
 *
 * @return {Array}
 */
var getInterpolatedArray = exports.getInterpolatedArray = function getInterpolatedArray() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (str || '').split(/{([^{}]*)}/g).map(function (value, index) {
    if (index % 2 === 0) {
      return value;
    }
    return obj[value] || "{".concat(value, "}");
  });
};