"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icons = void 0;
var _emptyIcon = _interopRequireDefault(require("./light/empty-icon-0.svg"));
var _emptyIcon2 = _interopRequireDefault(require("./light/empty-icon-1.svg"));
var _emptyIcon3 = _interopRequireDefault(require("./light/empty-icon-2.svg"));
var _emptyIcon4 = _interopRequireDefault(require("./light/empty-icon-3.svg"));
var _emptyIcon5 = _interopRequireDefault(require("./light/empty-icon-4.svg"));
var _emptyIcon6 = _interopRequireDefault(require("./light/empty-icon-5.svg"));
var _emptyIcon7 = _interopRequireDefault(require("./light/empty-icon-6.svg"));
var _emptyIcon8 = _interopRequireDefault(require("./light/empty-icon-7.svg"));
var _emptyIcon9 = _interopRequireDefault(require("./light/empty-icon-8.svg"));
var _emptyIcon10 = _interopRequireDefault(require("./light/empty-icon-9.svg"));
var _emptyIcon11 = _interopRequireDefault(require("./light/empty-icon-10.svg"));
var _emptyIcon12 = _interopRequireDefault(require("./light/empty-icon-11.svg"));
var _emptyIcon13 = _interopRequireDefault(require("./light/empty-icon-12.svg"));
var _emptyIcon14 = _interopRequireDefault(require("./light/empty-icon-13.svg"));
var _emptyIcon15 = _interopRequireDefault(require("./light/empty-icon-14.svg"));
var _emptyIcon16 = _interopRequireDefault(require("./light/empty-icon-15.svg"));
var _emptyIcon17 = _interopRequireDefault(require("./light/empty-icon-16.svg"));
var _emptyIcon18 = _interopRequireDefault(require("./light/empty-icon-17.svg"));
var _emptyIcon19 = _interopRequireDefault(require("./light/empty-icon-18.svg"));
var _emptyIcon20 = _interopRequireDefault(require("./light/empty-icon-19.svg"));
var _emptyIcon21 = _interopRequireDefault(require("./light/empty-icon-20.svg"));
var _emptyIcon22 = _interopRequireDefault(require("./light/empty-icon-21.svg"));
var _emptyIcon23 = _interopRequireDefault(require("./light/empty-icon-22.svg"));
var _emptyIcon24 = _interopRequireDefault(require("./light/empty-icon-23.svg"));
var _emptyIcon25 = _interopRequireDefault(require("./dark/empty-icon-0.svg"));
var _emptyIcon26 = _interopRequireDefault(require("./dark/empty-icon-1.svg"));
var _emptyIcon27 = _interopRequireDefault(require("./dark/empty-icon-2.svg"));
var _emptyIcon28 = _interopRequireDefault(require("./dark/empty-icon-3.svg"));
var _emptyIcon29 = _interopRequireDefault(require("./dark/empty-icon-4.svg"));
var _emptyIcon30 = _interopRequireDefault(require("./dark/empty-icon-5.svg"));
var _emptyIcon31 = _interopRequireDefault(require("./dark/empty-icon-6.svg"));
var _emptyIcon32 = _interopRequireDefault(require("./dark/empty-icon-7.svg"));
var _emptyIcon33 = _interopRequireDefault(require("./dark/empty-icon-8.svg"));
var _emptyIcon34 = _interopRequireDefault(require("./dark/empty-icon-9.svg"));
var _emptyIcon35 = _interopRequireDefault(require("./dark/empty-icon-10.svg"));
var _emptyIcon36 = _interopRequireDefault(require("./dark/empty-icon-11.svg"));
var _emptyIcon37 = _interopRequireDefault(require("./dark/empty-icon-12.svg"));
var _emptyIcon38 = _interopRequireDefault(require("./dark/empty-icon-13.svg"));
var _emptyIcon39 = _interopRequireDefault(require("./dark/empty-icon-14.svg"));
var _emptyIcon40 = _interopRequireDefault(require("./dark/empty-icon-15.svg"));
var _emptyIcon41 = _interopRequireDefault(require("./dark/empty-icon-16.svg"));
var _emptyIcon42 = _interopRequireDefault(require("./dark/empty-icon-17.svg"));
var _emptyIcon43 = _interopRequireDefault(require("./dark/empty-icon-18.svg"));
var _emptyIcon44 = _interopRequireDefault(require("./dark/empty-icon-19.svg"));
var _emptyIcon45 = _interopRequireDefault(require("./dark/empty-icon-20.svg"));
var _emptyIcon46 = _interopRequireDefault(require("./dark/empty-icon-21.svg"));
var _emptyIcon47 = _interopRequireDefault(require("./dark/empty-icon-22.svg"));
var _emptyIcon48 = _interopRequireDefault(require("./dark/empty-icon-23.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var icons = exports.icons = {
  light: [_emptyIcon["default"], _emptyIcon2["default"], _emptyIcon3["default"], _emptyIcon4["default"], _emptyIcon5["default"], _emptyIcon6["default"], _emptyIcon7["default"], _emptyIcon8["default"], _emptyIcon9["default"], _emptyIcon10["default"], _emptyIcon11["default"], _emptyIcon12["default"], _emptyIcon13["default"], _emptyIcon14["default"], _emptyIcon15["default"], _emptyIcon16["default"], _emptyIcon17["default"], _emptyIcon18["default"], _emptyIcon19["default"], _emptyIcon20["default"], _emptyIcon21["default"], _emptyIcon22["default"], _emptyIcon23["default"], _emptyIcon24["default"]],
  dark: [_emptyIcon25["default"], _emptyIcon26["default"], _emptyIcon27["default"], _emptyIcon28["default"], _emptyIcon29["default"], _emptyIcon30["default"], _emptyIcon31["default"], _emptyIcon32["default"], _emptyIcon33["default"], _emptyIcon34["default"], _emptyIcon35["default"], _emptyIcon36["default"], _emptyIcon37["default"], _emptyIcon38["default"], _emptyIcon39["default"], _emptyIcon40["default"], _emptyIcon41["default"], _emptyIcon42["default"], _emptyIcon43["default"], _emptyIcon44["default"], _emptyIcon45["default"], _emptyIcon46["default"], _emptyIcon47["default"], _emptyIcon48["default"]]
};